<template>
    <ConfirmDialog group="dialog" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-fluid">
                <h4><strong>{{$t('Managers.view.Header')}}</strong></h4>
                <div class="p-field p-grid">
                    <label for="name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('Managers.view.Name')}}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ name }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('Managers.view.Email')}}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ email }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('Managers.view.Phone')}}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{"+" + country_code }}{{"-" + mobile }}</p>
                    </div>
                </div>

                

                <div class="p-field p-grid">
                    <label for="description_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('Managers.view.Country')}}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ country_name }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('Managers.view.Status')}}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ $t(status) }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('Managers.view.CreationDate&Time')}}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ startdateformat(createdDate) }}</p>
                    </div>
                </div>

                <div class="p-d-flex p-jc-end" style="float: left">
                    <router-link :to="'/ManagerManagementEdit/' + $route.params.id"
                        ><Button :label="$t('Managers.view.Edit')" icon="pi pi-pencil" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2"> </Button></router-link
                    >
                    <!-- <Button @click="del($route.params.id)" label="delete" class="p-button-outlined p-button-danger p-mr-2 p-mb-2"><i class="pi pi-trash p-mr-2"></i>{{$t('button.delete')}}</Button> -->
                </div>

                <div class="p-d-flex p-jc-end p-ai-center">
                    <div>
                        <Button :label="$t('Managers.view.Back')" icon="pi pi-replay" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="$router.go(-1)"></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
import ManagerService from '../../../service/API/SuperAdmin/ManagerService.js';
// import {useRouter} from 'vue-router'
import moment from 'moment';
export default {
    data() {
        return {
           
           
            
                name: '',
                email: '',
                // pillImage: '',
                // image:'',
                mobile: '',
                country_name: '',
                status:'',
                createdDate:'',
                country_code:''
                // type:''
            
        };
    },
     created() {
        this.managerService = new ManagerService();
    },
    
    mounted() {
        this.managerService.viewManagerManagement(this.$route.params.id).then((res) => {
            this.name = res.data.data[0].name;
            this.email = res.data.data[0].email;
            this.mobile = res.data.data[0].mobile;
            this.country_name = res.data.data[0].country_name;
            this.status = res.data.data[0].status;
            this.createdDate = res.data.data[0].createdDate;
            this.country_code = res.data.data[0].country_code;
            // console.log(res.data.data.manager.country_name)
        });
    },
    methods:{
        startdateformat(value) {
            if (value) {
                // return moment(String(value)).format('YYYY/MM/DD h:m:s');
                return moment(String(value)).locale('ko').format('ll - LTS');
            }
        },
    }
};
</script>

<style scoped>


.p-fluid .p-button {
    width: auto;
}

.p-button{
    background: #000000;
    border: 1px solid #0a0a0a;
        color: white;
}

.product-image {
    padding-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 150px;
    height: 100px;
    /* margin-left: 25%; */
}
</style>